// Config for gms-qa.gigxr.com

export const BASE_API_URL = '/api/v1';
export const DEVELOPMENT = true;
export const GA_MEASUREMENT_ID = 'G-0GWGX3K338';
export const FIREBASE_DYNAMIC_LINK_URL = 'https://gigmobile.page.link';
export const ANDROID_PACKAGE_NAME = 'com.gigxr.gigmobile';
export const APPLICATION_INSIGHTS_INSTRUMENTATION_KEY = '';
export const SENTRY_ENVIRONMENT = 'qa';
export const BASE_GMS_URL = 'https://shodan.gigxr.dev';
export const GTM_ID = 'GTM-M85JQDZ';
