import { get } from 'svelte/store';
import { isUnity } from './unity';
import { getMobileOperatingSystem } from './util';
import { institutionIdStore, institutionMasquerade, isAuthenticated } from '../stores/core-store';
import { realInstitutionStore } from '../stores/institutions-store';
import { Logger } from './logs';

let _gtmID = '';

function setupGoogleAnalytics(gtmID) {
  window.dataLayer = window.dataLayer || [];

  if (!gtmID) {
    console.warn('Google Analytics is disabled.');
    return;
  }

  Logger.log(`Setting up Google Tag Manager: ${gtmID}`);
  _gtmID = gtmID;

  // const script = document.createElement('script');
  // script.async = true;
  // script.src = `https://www.googletagmanager.com/gtag/js?id=${_gtmID}`;
  // document.body.appendChild(script);

  gtag('js', new Date());
  gtag('config', _gtmID, {
    send_page_view: false,
    custom_map: {
      dimension1: 'platform',
      dimension2: 'institutionId',
      dimension3: 'institutionName',
    },
    cookie_flags: 'SameSite=Lax',
  });
}

function gtag() {
  window.dataLayer.push(arguments);
}

/**
 * Sends a page_view event to Google Analytics.
 *
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/pages
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/custom-dims-mets
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/single-page-applications
 * @param {string} path
 */
function sendGoogleAnalyticsPageView(path) {
  const dimensions = getGoogleAnalyticsDimensions();
  gtag('event', 'page_view', {
    // page_title: '',
    page_location: window.location.href,
    page_path: path,
    send_to: _gtmID,
    ...dimensions,
  });
}

function getGoogleAnalyticsDimensions() {
  const dimensions = {};

  // Dimension1 = platform
  if (isUnity()) {
    const os = getMobileOperatingSystem();
    if (os === 'iOS') {
      dimensions.platform = 'iOS';
    } else if (os === 'Android') {
      dimensions.platform = 'Android';
    } else {
      dimensions.platform = 'Unknown';
    }
  } else {
    dimensions.platform = 'Web';
  }

  if (!get(isAuthenticated)) {
    return dimensions;
  }

  // Dimension2 = institutionId
  const institutionId = get(institutionIdStore);
  if (institutionId) {
    dimensions.institutionId = institutionId;
  }

  // Dimension3 = instituionName
  const institution = get(realInstitutionStore);
  if (institution) {
    dimensions.institutionName = institution.institutionName;
  }

  return dimensions;
}

export { setupGoogleAnalytics, sendGoogleAnalyticsPageView };
