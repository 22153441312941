<script>
  import { beforeUpdate, onMount } from 'svelte';
  import Ripple from '@smui/ripple';
  import { link } from 'svelte-routing';
  import { NON_BREAKING_SPACE } from '../../util/constants';
  import { sessionCardDisplayedDate } from '../../util/dates';
  import ClientAppLogo from '../client-apps/ClientAppLogo.svelte';
  import { isUnity, isDeveloper } from '../../util/unity';
  import { clientApps } from '../../stores/client-apps-store';
  import { getAccountId } from '../../util/account';
  import { account } from '../../stores/core-store';
  import { fetchInstitutionLeaf } from '../../util/api/institutions';
  import { splitTextByUppercaseLetter } from '../../util/text';

  export let session;
  var institutionLeaf = null;
  onMount(async () => {
    institutionLeaf = await fetchInstitutionLeaf($account.institutionId);
  });

  let cardLink = '';
  $: if (session) {
    if (
      (isUnity() && session.createdById !== getAccountId()) ||
      (isUnity() && isDeveloper() && session.createdById === getAccountId()) ||
      (isUnity() && institutionLeaf?.canMobileCreateSessions)
    ) {
      cardLink = `/sessions/lobby/${session.sessionId}`;
    } else {
      cardLink = `/sessions/join/${session.sessionId}`;
    }
  }

  let clientAppName = '';
  let clientAppsById = new Map();
  let clientAppVersion = '';
  let createdByName = '';
  beforeUpdate(async () => {
    $clientApps.forEach((app) => clientAppsById.set(app.clientAppId, app.clientAppName));

    if (session) {
      if (clientAppsById.has(session.clientAppId)) {
        clientAppName = clientAppsById.get(session.clientAppId);
      }
    }

    if (session && session.createdBy) {
      createdByName = `${session.createdBy.firstName} ${session.createdBy.lastName}`;
    }

    if (session && session.clientAppVersion) {
      clientAppVersion = session.clientAppVersion;
    } else {
      clientAppVersion = '';
    }

    if (clientAppVersion === null || clientAppVersion === 'null') {
      clientAppVersion = '';
    }
  });
</script>

<a id="available-session-card-link-{session.sessionId}" href={cardLink} use:link>
  <div class="available-session-card" use:Ripple={{ ripple: true, color: 'surface' }}>
    <div class="available-session-card__client_app_logo">
      <ClientAppLogo {clientAppName} clientAppId={session.clientAppId} variant="medium" />
      <div class="available-session-card__client_app_logo_text">
        {clientAppName}
        {#if clientAppVersion !== ''}<br />ver: <span>{clientAppVersion}</span>{:else}<br />{/if}
      </div>
    </div>
    <ul class="card-details">
      <li class="card-details__title"><span class="session-name">{session.sessionName}</span></li>
      <li class="card-details__item">{sessionCardDisplayedDate(session)}</li>
      <li class="card-details__item">
        {session.sessionPermission ? splitTextByUppercaseLetter(session.sessionPermission) : NON_BREAKING_SPACE}
      </li>
      <li class="card-details__item">{createdByName ? `Created by ${createdByName}` : NON_BREAKING_SPACE}</li>
    </ul>
  </div>
</a>

<style>
  a:hover {
    text-decoration: none;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  li {
    margin-bottom: 0.2em;
  }

  .available-session-card {
    background: #fff;
    font-size: 13px;
    border-radius: 10px;
    box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
    padding: 16px;
    width: 303px;
    font-family: 'Barlow', sans-serif;
    display: flex;
  }

  .available-session-card > * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .available-session-card:hover {
    cursor: pointer;
  }

  .available-session-card__client_app_logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-right: thin solid #d2dce2;
    padding-right: 12px;
    padding-top: 8px;
    text-align: center;
  }

  .available-session-card__client_app_logo_text {
    text-transform: uppercase;
    font-size: 9px;
    margin-top: 9px;
  }

  .session-name {
    font-weight: bold;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* Number of lines to show */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .card-details {
    margin-left: 12px;
    padding-top: 5px;
  }

  .card-details__item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 150px;
  }

  .card-details__title {
    width: 150px;
    height: 34px;
    overflow: hidden;
    line-height: 1.2;
  }
</style>
