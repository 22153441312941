<script>
  import { link } from 'svelte-routing';
  import { breadcrumbPaths } from '../stores/core-store';
</script>

<!-- {#if $breadcrumbPaths && $breadcrumbPaths.length > 1 && !extendMobileBreadcrumbs}
  <div class="breadcrumb-nav-mobile">
    <a id="mobile-breadcrumb" href={$breadcrumbPaths[$breadcrumbPaths.length - 2].location} use:link>
      {`< ${NON_BREAKING_SPACE}`}{$breadcrumbPaths[$breadcrumbPaths.length - 1].name}
    </a>
  </div>
{/if} -->

{#if $breadcrumbPaths && $breadcrumbPaths.length > 1}
  <ol class="breadcrumb-nav-mobile">
    {#each $breadcrumbPaths.slice(-2) as path, index (path.name)}
      <li class="breadcrumb-nav-mobile__item">
        <a
          id="mobile-breadcrumb-{index}"
          class="breadcrumb-nav-mobile__link"
          href={path.location}
          use:link
        >{path.name}</a>
      </li>
    {/each}
  </ol>
{/if}

{#if $breadcrumbPaths && $breadcrumbPaths.length > 1}
  <ol class="breadcrumb-nav-desktop">
    {#each $breadcrumbPaths as path, index (path.name)}
      <li class="breadcrumb-nav-desktop__item">
        <a
          id="desktop-breadcrumb-{index}"
          class="breadcrumb-nav-desktop__link"
          href={path.location}
          use:link
        >{path.name}</a>
      </li>
    {/each}
  </ol>
{/if}

<style>
  li:not(:first-child):before {
    content: ' - ';
    margin: 0 0.2em;
  }

  .breadcrumb-nav-mobile {
    text-align: left;
    margin-bottom: 1em;
    list-style-type: none;
    padding-left: 0;
  }

  .breadcrumb-nav-mobile__item {
    display: inline;
  }

  .breadcrumb-nav-mobile__item:last-child a {
    font-weight: 400;
    pointer-events: none;
  }

  .breadcrumb-nav-desktop {
    display: none;
  }

  a {
    font-weight: 700;
  }

  @media (min-width: 1100px) {
    .breadcrumb-nav-mobile {
      display: none;
    }

    .breadcrumb-nav-desktop {
      display: block;
      text-align: left;
      list-style-type: none;
      margin: 0.5em 0 0 0;
      padding: 0;
    }

    .breadcrumb-nav-desktop__item {
      display: inline;
    }

    .breadcrumb-nav-desktop__item:last-child a {
      font-weight: 400;
      pointer-events: none;
    }
  }
</style>
